<template>
  <div class="page-container assets-wraper">
    <module-header title="投资相关参数" />
    <div class="plan">
      <div class="left-container">
        <div class="left-wraper left-block">
          <div class="wrap">
            <div class="wrap-head">
              <span class="wrap-head-title">大类配比</span>
              <span class="wrap-head-edit" @click="handleEditTable">修改</span>
            </div>
            <table
              style="width: 100%"
              border="1"
              cellspacing="0"
              bordercolor="#dfe6ec"
              :class="table1Obj.status ? 'hiden table-wrap' : 'table-wrap'"
            >
              <thead>
                <tr>
                  <th class="thead-th">
                    风险等级
                  </th>
                  <th class="thead-th">
                    现金类
                  </th>
                  <th class="thead-th">
                    固收类
                  </th>
                  <th class="thead-th">
                    权益类
                  </th>
                  <th class="thead-th">
                    另类
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in table1Obj.data" :key="i">
                  <td v-for="(w, _i) in item" :key="_i + i" class="tab-cell">
                    <template v-if="_i === 0">
                      <span class="tbody-td-span">{{ w.label }}</span>
                    </template>
                    <template v-else>
                      <span
                        v-if="table1Obj.status"
                        class="td-span"
                      >{{ w.number }}%</span>
                      <el-input
                        v-else
                        v-model="w.number"
                        type="number"
                        :disabled="table1Obj.status"
                      >
                        <span slot="suffix">%</span>
                      </el-input>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!table1Obj.status" class="text-center mt30">
              <el-button @click="handleCancel">
                返回
              </el-button>
              <el-button type="primary" @click="handleConfirm">
                确定
              </el-button>
            </div>
            <div class="line mt30" />
          </div>
          <div class="wrap">
            <div class="wrap-head">
              <span class="wrap-head-title">资产配比对应参数</span>
              <span class="wrap-head-edit" @click="handleEditTable2">修改</span>
            </div>
            <table
              style="width: 100%"
              border="1"
              cellspacing="0"
              bordercolor="#dfe6ec"
              :class="table2Obj.status ? 'hiden table-wrap' : 'table-wrap'"
            >
              <thead>
                <tr>
                  <th class="thead-th">
                    风险等级
                  </th>
                  <th class="thead-th">
                    预期年化回报率
                  </th>

                  <th class="thead-th">
                    组合标准差
                  </th>
                  <th class="thead-th">
                    组合最大回撤
                  </th>
                  <th class="thead-th">
                    90%置信区间组合 收益下限
                  </th>
                  <th class="thead-th">
                    90%置信区间组合 收益上限
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in table2Obj.data" :key="i">
                  <td class="tab2-cell">
                    <span class="tbody-td-span">{{ item.label }}</span>
                  </td>
                  <td class="tab2-cell">
                    <span
                      v-if="table2Obj.status"
                      class="td-span"
                    >{{ item.annualRateOfReturn }}%</span>
                    <el-input
                      v-else
                      v-model="item.annualRateOfReturn"
                      type="number"
                      :disabled="table2Obj.status"
                    >
                      <span slot="suffix">%</span>
                    </el-input>
                  </td>

                  <td class="tab2-cell">
                    <span
                      v-if="table2Obj.status"
                      class="td-span"
                    >{{ item.standardDeviation }}%</span>
                    <el-input
                      v-else
                      v-model="item.standardDeviation"
                      type="number"
                      :disabled="table2Obj.status"
                    >
                      <span slot="suffix">%</span>
                    </el-input>
                  </td>
                  <td class="tab2-cell">
                    <span
                      v-if="table2Obj.status"
                      class="td-span"
                    >{{ item.maxDrawback }}%</span>
                    <el-input
                      v-else
                      v-model="item.maxDrawback"
                      type="number"
                      :disabled="table2Obj.status"
                    >
                      <span slot="suffix">%</span>
                    </el-input>
                  </td>
                  <td class="tab2-cell">
                    <span
                      v-if="table2Obj.status"
                      class="td-span"
                    >{{ item.intervalLeft }}%</span>
                    <el-input
                      v-else
                      v-model="item.intervalLeft"
                      type="number"
                      :disabled="table2Obj.status"
                    >
                      <span slot="suffix">%</span>
                    </el-input>
                  </td>
                  <td class="tab2-cell">
                    <span
                      v-if="table2Obj.status"
                      class="td-span"
                    >{{ item.intervalRight }}%</span>
                    <el-input
                      v-else
                      v-model="item.intervalRight"
                      type="number"
                      :disabled="table2Obj.status"
                    >
                      <span slot="suffix">%</span>
                    </el-input>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!table2Obj.status" class="text-center mt30">
              <el-button @click="handleCancel2">
                返回
              </el-button>
              <el-button type="primary" @click="handleConfirm2">
                确定
              </el-button>
            </div>
            <div class="line mt30" />
          </div>
          <div class="wrap">
            <div class="wrap-head">
              <span class="wrap-head-title">回测指标选择</span>
              <span class="wrap-head-edit" @click="handleEdittarget">修改</span>
            </div>
            <div class="target-index-container">
              <div
                v-for="(item, i) in targetObj.data"
                :key="i"
                class="target-index-item"
              >
                <div class="target-index-title-w">
                  <div class="target-index-title">
                    {{ item.type }}
                  </div>
                  <div class="target-index-subtitle">
                    (按排序)
                  </div>
                </div>
                <div
                  v-for="w in item.indicators"
                  :key="w.id"
                  class="target-item-text"
                >
                  {{ w.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="wrap mt30">
            <div class="wrap-head">
              <span class="wrap-head-title">二级产品分类</span>
              <span class="wrap-head-edit" @click="handleEditProduct">修改</span>
            </div>
            <div class="target-index-container">
              <div
                v-for="(item, i) in twoMenu"
                :key="i"
                class="target-index-item"
              >
                <div class="target-index-title-w">
                  <div class="target-index-title">
                    {{ item.typeName }}
                  </div>
                </div>
                <template v-for="w in item.subProductTypes">
                  <div
                    v-if="!w.deleted"
                    :key="w.id"
                    class="target-item-text"
                  >
                    {{ w.typeName }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogTarget.flag"
      :close-on-click-modal="false"
      :show-close="false"
      width="650px"
      center
      class="dialog-wrapper"
    >
      <div class="dialog-head">
        回测数据选择
      </div>
      <div class="dialog-con">
        <div
          v-for="(item, i) in dialogTarget.formArr"
          :key="i"
          class="dialog-con-item"
        >
          <p class="dialog-con-item-title">
            {{ item.type }}
          </p>
          <div class="dialog-con-select-wrap">
            <div
              v-for="(w, _i) in item.data"
              :key="_i + i"
              class="dialog-select-item-w"
            >
              <span
                v-if="_i !== 0"
                class="el-icon-remove select-del-icon"
                @click="handleDelSelect(i, _i)"
              />
              <el-select
                v-model="w.value"
                placeholder="请选择"
                class="dialog-select-item"
                @change="handleChangeSelect(i, _i)"
              >
                <el-option
                  v-for="_w in w.options"
                  :key="_w.value"
                  :label="_w.label"
                  :value="_w.value"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleDialogEmpty">返回</el-button>
        <el-button type="primary" @click="handleDialogConfirm">确定</el-button>
      </span>
    </el-dialog>
    <ProductInfoDialog
      ref="productInfoDialog"
      :get-two-menu-data="getTwoMenuData"
      :two-menu="twoMenu"
    />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import SplitPane from 'vue-splitpane'
import { CommonMudule } from '@/store/modules/common'
import { libilityListStatus } from '@/types/data'
import {
  table1List,
  targetList,
  targetListAvailable,
  targetListAvailableSave,
  table1Save,
  table2Earnings,
  table2Save,
  twoMenu
} from '@/api/manage'
import { EmptyForm } from '@/utils/emptyForm'
import { IsToFixed, isPercentageStr } from '@/utils/IsToFixed'
import moduleHeader from '@/components/moduleHeader/index.vue'
import ProductInfoDialog from './template/productInfoDialog.vue'
@Component({
  name: 'pensionPlan',
  components: {
    SplitPane,
    moduleHeader,
    ProductInfoDialog
  },
})
export default class extends Vue {
  /**
   * 投资规划相关参数调整
   * table1Obj 表格相关
   *    Status 表格状态 默认不可编辑
   *      true 不可编辑(预览)
   *      false 可编辑(修改)
   *    data 表格数据
   */
  table1Obj = {
    status: true,
    data: [],
  };
  /**
   * table2Obj格式 同 table1Obj
   */
  table2Obj = {
    status: true,
    data: [],
  };

  copyObj = {
    // copy数据 编辑时做返回使用
    // 用户可同时修改俩个表格数据， 固维护俩个临时变量
    copyTable1: null,
    copyTable2: null,
  };
  /**
   * 回测指标选择
   */
  targetObj = {
    data: [
      // {
      //   title: '现金类',
      //   indicators: [
      //     {
      //       id: '3a0438da-b4d9-4a65-a96c-c0753e3c9795',
      //       name: '货基7日年化平均值',
      //     }
      //   ],
      // }
    ],
  };

  /**
   * 回测数据指标弹窗
   **/
  dialogTarget = {
    flag: false,
    formArr: [
      // {
      //   type: '现金类',
      //   data: [
      //     {
      //       options: [
      //         {
      //           value: 'a',
      //           label: '活期存款利率',
      //         }
      //       ],
      //       value: '',
      //     },
      //   ],
      // },
    ],
  };

  /**
 * 二级菜单列表
 */
  twoMenu=[]
  created() {
    this.getQueryAll()
  }

  async getQueryAll() {
    const res = await Promise.all([
      targetListAvailable(),
      table1List(),
      table2Earnings(),
      twoMenu()
    ])
    if (res) {
      this.getQueryTarget(res[0])
      this.getQueryTable1(res[1])
      this.getQueryTable2(res[2])
      this.getTwoMenuData(res[3])
    }
  }
  /**
   * getQueryTable1
   * 获取表格1数据
   * 数据处理 [{},{},...] => [[{},{}],[{},{}],...]
   */
  async getQueryTable1(list) {
    const { data } = list
    var obj = {}
    let _data = []

    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      const objArr = Object.getOwnPropertyNames(obj)
      if (objArr.length === 0) {
        obj[item.riskType] = [
          {
            ...item,
            number: IsToFixed(item.suggestPercentage, 100),
            [item.productTypeName]: item.suggestPercentage,
          },
        ]
      } else {
        if (!objArr.includes(item.riskType)) {
          obj[item.riskType] = [
            {
              ...item,
              number: IsToFixed(item.suggestPercentage, 100),
              [item.productTypeName]: item.suggestPercentage,
            },
          ]
        } else {
          obj[item.riskType].push({
            ...item,
            number: IsToFixed(item.suggestPercentage, 100),
            [item.productTypeName]: item.suggestPercentage,
          })
        }
      }
    }
    const TypeRatioHead = ['现金类', '固收类', '权益类', '另类']
    for (let i = 0; i < libilityListStatus.length; i++) {
      const _item = libilityListStatus[i]
      for (const k in obj) {
        if (_item.value === k) {
          const _label = _item
          const cloneObjArr = []
          for (let i = 0; i < TypeRatioHead.length; i++) {
            const _type = TypeRatioHead[i]
            const __type = obj[k].find(
              (item) => item.productTypeName === _type
            )
            cloneObjArr.push(__type)
          }
          cloneObjArr.unshift(_label)
          _data = [..._data, cloneObjArr]
        }
      }
    }

    this.table1Obj.data = _data
  }

  /**
   * 表格二
   */
  async getQueryTable2(list) {
    const { data } = list
    const _data = []
    for (let i = 0; i < libilityListStatus.length; i++) {
      const _item = libilityListStatus[i]

      data.forEach((item) => {
        if (_item.value === item.riskType) {
          const _label = _item
          _data.push({
            ...item,
            intervalLeft: parseFloat(item.intervalLeft),
            intervalRight: parseFloat(item.intervalRight),
            label: _label.label,
          })
        }
      })
    }

    this.table2Obj.data = _data
    this.table2Obj.clonedata = data
  }

  /**
   * 回测指标数据
   */
  async getQueryTarget(list) {
    const { statusCode, data } = list
    statusCode === 0 && data && (this.targetObj.data = data)
  }

  async getTwoMenuData(list) {
    this.twoMenu = list.data
  }

  handleEditProduct() {
    this.$refs.productInfoDialog.show()
  }
  /**
   * 弹窗下拉框
   */
  async getQueryTargetAvailable() {
    const { data } = await targetList()
    var _data = []
    data.forEach((item) => {
      for (let i = 0; i < item.indicators.length; i++) {
        const w = item.indicators[i]
        w.label = w.name
        w.value = w.id
      }

      const obj = { type: '', data: [] }
      obj.type = item.type
      const _fliter = item.indicators.filter((item) => !item.disable)
      if (_fliter.length) {
        for (let j = 0; j < _fliter.length; j++) {
          const n = _fliter[j]
          obj.data.push({
            options: item.indicators,
            value: n.id,
          })
        }
      } else {
        obj.data = [
          {
            options: item.indicators,
            value: item.indicators[0].id,
          },
        ]
      }
      _data.push(obj)
    })
    this.dialogTarget.formArr = _data
  }

  /**
   * 处理表格编辑
   */
  handleEditTable() {
    this.copyObj.copyTable1 = JSON.parse(JSON.stringify(this.table1Obj.data))
    this.table1Obj.status = false
  }
  /**
   * 返回
   * handleCancel
   */
  handleCancel() {
    this.table1Obj.data = this.copyObj.copyTable1
    this.copyObj.copyTable1 = null
    this.table1Obj.status = true
  }
  /**
   * 处理表格2编辑
   */
  handleEditTable2() {
    this.copyObj.copyTable2 = JSON.parse(JSON.stringify(this.table2Obj.data))
    this.table2Obj.status = false
  }

  /**
   * 表格一(大类配比) 确认
   * handleConfirm
   */
  async handleConfirm() {
    const { data } = this.table1Obj
    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      let num = 0
      for (let j = 0; j < item.length; j++) {
        const _item = item[j]

        if (_item.number) {
          num += +_item.number
        }
      }
      if (num !== 100) {
        this.$message.warning(
          `类型: ${item[0].label} 累加总和必须等于100%,请重新修改`
        )
        return
      }
    }

    const newData = []
    for (let i = 0; i < data.length; i++) {
      const item = data[i]
      item.forEach((w) => {
        const _num = w.number / 100
        if (w.id && _num !== w.suggestPercentage) {
          newData.push({
            ...w,
            suggestPercentage: _num,
          })
        }
      })
    }

    // 判断请求是否继续
    if (newData.length) {
      const res = await table1Save(newData)
      if (res.statusCode === 0) {
        this.getQueryTable1(await table1List())
      }
    }
    this.copyObj.copyTable1 = null
    this.table1Obj.status = true
  }

  /**
   * 表格二(资产配比对应参数) 确认
   * handleConfirm2
   */
  async handleConfirm2() {
    const { table2Obj } = this
    const { data, clonedata } = table2Obj
    const newData = []
    data.forEach((item, i) => {
      if (
        parseFloat(item.annualRateOfReturn) !==
          clonedata[i].annualRateOfReturn ||
        parseFloat(item.standardDeviation) !== clonedata[i].standardDeviation ||
        parseFloat(item.maxDrawback) !== clonedata[i].maxDrawback ||
        item.intervalLeft !== clonedata[i].intervalLeft ||
        item.intervalRight !== clonedata[i].intervalRight
      ) {
        newData.push({
          ...item,
          intervalLeft: item.intervalLeft + '%',
          intervalRight: item.intervalRight + '%',
        })
      }
    })
    if (newData.length) {
      const res = await table2Save(newData)
      if (res.statusCode === 0) {
        this.getQueryTable1(await table1List())
      }
    }
    this.copyObj.copyTable2 = null
    this.table2Obj.status = true
  }
  /**
   * 表格二(资产配比对应参数) 返回
   * handleCancel2
   */
  handleCancel2() {
    this.table2Obj.data = this.copyObj.copyTable2
    this.copyObj.copyTable2 = null
    this.table2Obj.status = true
  }
  // 编辑数据回测按钮 弹窗 true
  handleEdittarget() {
    this.getQueryTargetAvailable()
    this.dialogTarget.flag = true
  }
  /**
   * 回测数据选择 dialog del
   * handleDelSelect
   */
  handleDelSelect(i, sunI) {
    this.dialogTarget.formArr[i].data.splice(sunI, 1)
  }
  /**
   * 回测数据选择 下拉选中
   * handleChangeSelect
   */
  handleChangeSelect(i, sunI) {
    /**
     * 如果用户选择的最后一行，自动添加一个下拉框
     */
    const data = JSON.parse(JSON.stringify(this.dialogTarget.formArr[i].data))
    const selectEng = data.length
    if (selectEng - 1 === sunI) {
      this.dialogTarget.formArr[i].data.push({ ...data[sunI], value: '' })
    }
  }
  /**
   * dialog 回测数据选择 确认
   * handleDialogConfirm
   */
  async handleDialogConfirm() {
    /**
     * 数据去重 扁平化操作
     * {
     *  type: '现金类',
     *  data: [
     *    {
     *      options: [
     *        {
     *          value: 'a',
     *          label: '活期存款利率',
     *        }
     *      ],
     *      value: '',
     *    },
     *  ],
     * },
     * ===>
     * [
     *    {
     *    type: item.type,
     *    indicators: [{id:1,...},{...},...]
     *    },...
     * ]
     */
    //

    const { formArr } = this.dialogTarget
    const _arr = []
    for (let i = 0; i < formArr.length; i++) {
      const item = formArr[i]
      const w = item.data
      const hash = {}
      const _valueArr = []
      const orderedArray = w.reduce((accumulator, currentValue) => {
        if (currentValue.value !== '' && !hash[currentValue.value]) {
          hash[currentValue.value] = true
          _valueArr.push(currentValue.value)
          accumulator.push(currentValue)
        }

        return accumulator
      }, [])

      // 处理选择和删除数据
      const singleArrShow = []
      const singleArrHide = []
      const v = orderedArray[0].options
      _valueArr.forEach((n) => {
        const _n = { ...v.find((h) => h.id === n) }
        _n.disable = false
        singleArrShow.push(_n)
      })

      v.forEach((n) => {
        if (!_valueArr.includes(n.id)) {
          singleArrHide.push({
            ...n,
            disable: true,
          })
        }
      })

      // 行 数据整合 合并数组
      const singleArr = singleArrShow.concat(singleArrHide)
      // 排序
      singleArr.forEach((item, i) => {
        item.ordinal = i
      })
      // 数组最终整合
      _arr.push({
        type: item.type,
        indicators: singleArr,
      })
    }

    const res = await targetListAvailableSave(_arr)
    if (res.statusCode === 0) {
      this.handleDialogEmpty()
      this.getQueryTarget(await targetListAvailable())
    }
  }

  /**
   * 清空form
   */
  handleDialogEmpty() {
    EmptyForm(this.dialogTarget)
    this.dialogTarget.flag = false
  }
}
</script>
<style lang="scss">
.dialog-wrapper {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog--center .el-dialog__body {
    padding-top: 0;
  }
  .el-checkbox {
    font-weight: 400;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-input--small {
  line-height: 2.5;
}
.table-wrap {
  border-collapse: collapse;
  border: 1px solid #dfe6ec;
  .thead-th {
    background-color: #d3e5ff;
    line-height: 18px;
    padding: 8px 0;
    font-weight: 500;
    font-size: 14px;
    color: #3a3a3a;
  }
  .td-span {
    display: flex;
    height: 32px;
    font-size: 12px;
    color: #c0c4cc;
    justify-content: center;
    align-items: center;
  }
  .tbody-td-span {
    display: inline-block;
    width: 100%;
    color: #1f2d3d;
    text-align: center;
    font-size: 12px;
  }
  .tab-cell {
    padding: 8px 10px;
    width: 20%;
  }
  .tab2-cell {
    padding: 8px 10px;
    width: calc(100% / 6);
  }
  tr:hover {
    background-color: #f5f7fa;
  }
}

.wrap {
  width: 100%;
  .wrap-head {
    margin-bottom: 20px;
    .wrap-head-title {
      font-size: 18px;
      color: #333;
      font-weight: 500;
    }
    .wrap-head-edit {
      color: #2179ff;
      padding-left: 30px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.mt30 {
  margin-top: 30px;
}
.text-center {
  text-align: center;
}
.target-index-container {
  .target-index-item {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;

    .target-index-title-w {
      display: flex;
      width: 100%;
      align-items: center;
      .target-index-title {
        font-size: 16px;
      }
      .target-index-subtitle {
        font-size: 12px;
        margin-left: 8px;
        color: #ccc;
      }
    }
    .target-item-text {
      font-size: 14px;
      margin-top: 20px;
      margin-right: 50px;
    }
  }
}
.dialog-head {
  display: flex;
  width: 100%;
  height: 82px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333333;
  border-bottom: 1px solid #eeeeee;
}
.dialog-con {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 60px 0 80px;
  box-sizing: border-box;
  .dialog-con-item {
    width: 100%;
    margin-top: 30px;
    .dialog-con-item-title {
      font-size: 14px;
      color: #333;
    }
    .dialog-con-select-wrap {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .dialog-select-item-w {
        position: relative;
        width: 33.333%;
        box-sizing: border-box;
        margin-top: 20px;
        padding-right: 20px;

        .select-del-icon {
          position: absolute;
          z-index: 1;
          left: -7px;
          top: -7px;
          color: #ff0000;
          cursor: pointer;
        }
        .dialog-select-item {
          width: 100%;
        }
      }
    }
  }
}
</style>
