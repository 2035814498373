
/**
 *
 * @param formData
 * 浅清空 data
 *  格式 {name:'' obj:{}, array:[]}
 */
export const EmptyForm = (formData:any) => {
  for (const k in formData) {
    if (formData[k] instanceof Object) {
      formData[k] = {}
    } else if (formData[k] instanceof Array) {
      formData[k] = []
    } else {
      formData[k] = ''
    }
  }
}
