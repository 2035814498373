import request from '@/utils/request'
import {
  TargetListAvailableSave,
  Table1Save,
  Table2Save
} from '@/types/manage'
/**
 * 回测指标选择
 */
export const targetList = () =>
  request({
    url: '/blackcat/indicator/list',
    method: 'get'
  })
/**
 * 回测指标选择 disable: false
 */
export const targetListAvailable = () =>
  request({
    url: '/blackcat/indicator/list/available',
    method: 'get'
  })
/**
 * 回测指标选择 弹窗 save
 */
export const targetListAvailableSave = (data: TargetListAvailableSave[]) =>
  request({
    url: '/blackcat/indicator/batch/save',
    method: 'post',
    data
  })

/**
 * 资产配置调整表1
 */
export const table1List = () =>
  request({
    url: '/blackcat/allocation/suggest/list',
    method: 'get'
  })
/**
 * 资产配置调整表 save
 */
export const table1Save = (data: Table1Save[]) =>
  request({
    url: '/blackcat/allocation/suggest/batch/save',
    method: 'post',
    data
  })

/**
 * 资产配置表格2
 */
export const table2Earnings = () =>
  request({
    url: '/blackcat/prospective/earnings/all',
    method: 'get'
  })

/**
 * 资产配置表格2 save
 */
export const table2Save = (data: Table2Save[]) =>
  request({
    url: '/blackcat/prospective/earnings/batch/save',
    method: 'post',
    data
  })

/**
 * 二级菜单列表
 */
export const twoMenu = () =>
  request({
    url: '/blackcat/product/type/list/all',
    method: 'get'
  })

/**
 * 二级菜单列表 批量保存
 */
export const twoMenuSave = (data:any) =>
  request({
    url: '/blackcat/product/type/batch/save',
    method: 'post',
    data
  })
