

























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { twoMenuSave, twoMenu
} from '@/api/manage'
import { cloneDeep } from 'lodash'

@Component
export default class ProductInfo extends Vue {
  @Prop() twoMenu!: any;
  @Prop() getTwoMenuData!:Function
  formArr = []
  visible: boolean = false;
  show() {
    this.formArr = cloneDeep(this.twoMenu)
    this.visible = true
  }
  close() {
    this.formArr = []
    this.visible = false
  }
  async confirm() {
    const _form = cloneDeep(this.formArr)
    const obj:any = {}
    _form.forEach((item:any) => {
      let _seq = 0
      const _arr:any = []
      item.subProductTypes.forEach((w:any) => {
        if (w.typeName !== '') {
          if (obj[w.typeName]) {
            obj[w.typeName] += 1
          } else {
            obj[w.typeName] = 1
          }
          _seq++
          if (!w.id) {
            w.seq = _seq
          }
          _arr.push(w)
        }
      })

      item.subProductTypes = _arr
    })
    let flag = true
    for (const k in obj) {
      if (obj[k] > 1) {
        flag = false
      }
    }

    if (!flag) {
      this.$message({
        type: 'warning',
        message: '产品名不能重复,请重新修改',
      })
      return
    }
    await twoMenuSave(_form)
    this.visible = false
    this.formArr = []
    this.getTwoMenuData(await twoMenu())
  }
  handleChange(w: any, subProductTypes: any) {
    const flagArr: any = []
    subProductTypes.forEach((item: any) => {
      if (item.id === w.id) {
        flagArr.push(!w.deleted)
      } else {
        flagArr.push(item.deleted)
      }
    })
    if (flagArr.findIndex((item: any) => item === false) === -1) {
      this.$message({
        type: 'warning',
        message: '请至少保留一种产品属性下本产品分类',
      })
    } else {
      w.deleted = !w.deleted
    }
  }
  handleAdd(sub:any, parentId:any) {
    sub.push({ parentId, level: '1', typeName: '', deleted: false })
  }
}
